<template>
  <div class="contactUs">
    <div class="content_area">
      <div class="title"><span>//</span>联系我们</div>
      <div class="main">
        <div>
          培训部电话：0592-5068086
        </div>
        <div>
          邮箱：sqz1977@xiffa.com.cn邵小姐
        </div>
        <!-- <div>
          上海市国际货运代理行业协会（Shanghai International Freight Forwarders Association）是在我国改革开放不断深化，国际货运代理业快速发展条件下，于1992年9月成立，是我国（除港澳台地区外）最早成立的省市级国际货运代理行业协会。协会本着"指导、服务、协调、保护"的宗旨，协助政府有关部门加强国际货运代理行业管理，维护上海国际货运代理市场的经营秩序；研究国际货运代理业发展趋势；代表会员利益，反映行业呼声；开展各类培训，提高从业人员素质；协调行业内外各种关系，促进上海国际货运代理行业的健康发展。上海市国际货运代理行业协会现有会员单位420家。
        </div>
        <div>
          上海市国际货运代理行业协会的组织机构为会员大会、理事会、监事会、领导机构，并设立法律、服务规范、信息系统、信用建设、国际非贸易物品搬运、无船承运业务和资源整合等专业委员会，秘书处为协会的日常工作机构。        </div>
        <div>
          协会一贯重视服务质量的不断提高，始终坚持“服务行业、服务企业”的办会理念。协会根据行业发展的实际情况，先后开展从业人员的专业业务知识培训，推动行业ISO2000质量管理体系认证工作，制定行业信用评估管理办法，有效维护行业利益并实现国际货运代理业免征增值税。倡导货代联盟试点，促进非贸易物品搬运业务的规范化，探索行业诚信建设评估办法，配合政府有关部门开展各类调研，制定行业服务规范，引导企业参与行业管理与自律，努力增强会员企业市场竞争力，力求为中国国际货运代理事业发展作出贡献。        </div> -->
      </div>
      <div class="adress">
        <mapPage :longitude="longitude" :dimension="dimension"></mapPage>
      </div>
    </div>
    <div class="board">
      <div class="content">
        <div class="tc title">
          如果您在使用本网站时有任何意见或建议，请写下您的意见或者建议。我们会尽快答复您，感谢您的关注~
        </div>
        <div class="evaluate">
          <el-form
            :model="evaluateVal"
            ref="evaluateForm"
            :rules="evaluateRules"
          >
            <div class="clearfix">
              <el-form-item prop="name" class="fl">
                <el-input
                  size="small"
                  class="input"
                  v-model="evaluateVal.name"
                  type="email"
                  placeholder="名字"
              /></el-form-item>
              <el-form-item prop="email" class="fl">
                <el-input
                  size="small"
                  class="input1"
                  v-model="evaluateVal.email"
                  placeholder="邮箱"
              /></el-form-item>
              <el-form-item prop="phone" class="fl">
                <el-input
                  size="small"
                  class="input1"
                  type="number"
                  v-model="evaluateVal.phone"
                  placeholder="电话"
              /></el-form-item>
            </div>
            <el-form-item prop="textarea">
              <el-input
                type="textarea"
                class="textarea"
                placeholder="请写下您想说的话"
                v-model="evaluateVal.content"
            /></el-form-item>
            <div class="tc">
              <el-button
                plain
                size="small"
                class="submit"
                @click="submit"
                :loading="btnLoading"
                >提交</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validateTelephone, validateEmail } from "~/baseUtils/validate";
import mapPage from "~/baseComponents/map";
export default {
  components: { mapPage },
  metaInfo: {
    title: "厦门货代协会--联系我们",
  },
  data() {
    return {
      evaluateVal: {
        name: "",
        email: "",
        phone: "",
        content: "",
      },
      longitude: 118.093989,
      dimension: 24.483774,
      btnLoading: false,
      evaluateRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (!validateTelephone(value)) {
              this.codeBtnDisabled = true;
              callback("手机号格式错误");
            } else {
            }
          },
          trigger: "blur",
        },
        email: {
          validator: async (rule, value, callback) => {
            if (!validateEmail(value)) {
              this.codeBtnDisabled = true;
              callback("邮箱格式错误");
            } else {
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    async submit() {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      }
      if (!this.evaluateVal.content) {
        this.$message.warning("输入建议不能为空");
        return;
      }
      this.btnLoading = true;
      this.evaluateVal.source = this.PJSource;
      let res = await this.$store.dispatch(
        "API_index/suggestionSave",
        this.evaluateVal
      );
      this.btnLoading = false;
      if (res.success) {
        this.$message.success("提交成功");
        this.evaluateVal = {};
      } else {
      }
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.contactUs {
  min-height: 800px;
  background: #ffffff;
  .content_area {
    padding: 30px 0 50px;
    .title {
      font-size: 24px;
      span {
        color: #ff5155;
        margin-right: 10px;
      }
    }
    .main {
      margin: 24px 0;
      div {
        text-indent: 2em;
        line-height: 25px;
      }
    }
    .adress {
      height: 440px;
      // border: 1px solid #ff5155;
    }
  }
  .board {
    width: 100%;
    height: 412px;
    background: #0D6BB9;
    .content {
      width: 800px;
      margin: 0 auto;
      padding: 36px 0;
      .title {
        color: #fff;
      }
      .evaluate {
        margin-top: 36px;
        .input {
          width: 200px;
        }
        .input1 {
          width: 280px;
          margin-left: 16px;
        }

        .textarea {
          margin-top: 24px;
          min-height: 154px;
        }
        .submit {
          width: 115px;
          margin-top: 8px;
          color: #0D6BB9;
        }
      }
    }
  }
  /deep/.el-textarea__inner {
    min-height: 154px !important;
  }
  /deep/.el-form-item__error {
    color: #d8563a;
    margin-left: 16px;
  }
}
</style>
